@import 'src/styles/media';
@import 'src/styles/font-stack';

.blippLogotype {
  width: auto;
  height: 3.9375rem;

  path {
    fill: var(--color-white);
  }
}
.address {
  @include font-regular-14;
  width: 100%;
  text-align: center;
  margin-bottom: 0;

  @include media(md) {
    text-align: left;
  }
}
