@import 'src/styles/media';

.header {
  --inverted-header-color: var(--color-white);

  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(2, auto);
  padding: 0 1rem;
  min-height: 4rem;
  align-items: center;
  max-width: var(--max-size-lg);
  margin: 0 auto;
  z-index: var(--layer-sun);

  @include media(md) {
    grid-template-columns: repeat(3, auto);
  }

  a {
    z-index: var(--layer-sun);
  }
}

.logotype {
  width: auto;
  height: 2rem;

  .inverted & {
    path {
      fill: var(--inverted-header-color);
    }
  }
}
