@import 'src/styles/media';
@import 'src/styles/font-stack';

.navList {
  &:not(:first-child) {
    padding-top: 1rem;
  }

  @include media(md) {
    padding-top: 1rem;
  }

  .heading {
    @include font-title-24;
    margin-bottom: calc(0.5rem + 0.25rem);
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    li {
      @include font-regular-14;
    }
  }
}
