@import 'src/styles/media';

.size {
  width: var(--icon-size);
  height: var(--icon-size);
  display: flex;
  align-items: center;
}

.svg {
  overflow: auto;
}

.stroke {
  path {
    stroke: var(--icon-color);
    vector-effect: non-scaling-stroke;
  }
}

.fill {
  path {
    fill: var(--icon-color);
  }
}
