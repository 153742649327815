@import 'src/styles/media';
@import 'src/styles/font-stack';
@import 'src/styles/not-supports-gap';

.metricCard {
  display: flex;
  gap: 0.875rem;
  padding: 1rem;
  width: fit-content;
  border-radius: 0.875rem;
  color: var(--color-text-primary);

  @include not-supports-gap {
    > .icon + .text {
      margin-top: 0.875rem;
    }
  }

  .text {
    display: flex;
    flex-direction: column;
  }
}

.small {
  .text {
    gap: 0.125rem;
    .label {
      @include font-overline-12;
    }
    .value {
      @include font-title-20;
      white-space: pre-line;
    }
  }
}

.medium {
  padding: 1.5rem;
  .text {
    gap: 0.125rem;
    .label {
      @include font-overline-12;
    }
    .value {
      @include font-title-24;
    }
  }
}

.large {
  padding: 2rem;
  .text {
    gap: 0.25rem;
    .label {
      @include font-overline-16;
    }
    .value {
      @include font-title-32;
      white-space: pre-line;
    }
  }
}

.left {
  .icon {
    align-self: left;
  }
  .label,
  .value {
    text-align: left;
  }
}

.center {
  .icon {
    align-self: center;
  }
  .label,
  .value {
    text-align: center;
  }
}

.vertical {
  flex-direction: column;
}

.horizontal {
  align-items: center;
  gap: 1rem;

  @include not-supports-gap {
    > .icon + .text {
      margin-left: 0.875rem;
      margin-top: 0;
    }
  }

  &.large {
    gap: 1.125rem;

    @include not-supports-gap {
      > .icon + .text {
        margin-left: 1.125rem;
      }
    }
  }

  .label,
  .value {
    text-align: left;
  }
}
