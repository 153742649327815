@import 'src/styles/font-stack';

$accordion-item-height: 4rem;

.accordion-item {
  overflow-y: clip;
  max-height: $accordion-item-height;
  transition: max-height 0.3s ease-in-out;
  color: var(--color-dark-blue);

  .heading {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0 1rem;
    height: $accordion-item-height;
    position: relative;

    .title {
      @include font-input-label-20;
    }

    .title:first-letter {
      text-transform: uppercase;
    }

    .icon,
    .icon {
      position: absolute;
      right: 0;
      transition: opacity 0.3s ease-out;
    }

    .icon-opened {
      opacity: 0;
    }
  }

  .content {
    transition: opacity 0.5s ease-out;
    opacity: 0;
    padding-bottom: 1rem;
    pointer-events: none;
  }

  &[data-open='true'] {
    /**
    * $hard-coded-content-height-constant is a workaround for being able to use `max-height` as a animation property.
    *
    * @todo calculate the constant with Javascript.
    */
    $hard-coded-content-height-constant: 2000px;

    max-height: calc($accordion-item-height + $hard-coded-content-height-constant);

    .icon-closed {
      opacity: 0;
    }

    .icon-opened {
      opacity: 1;
    }

    .content {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &.inverted {
    color: var(--color-white);
  }
}
