@import 'src/styles/not-supports-gap';
@import 'src/styles/media';

.user-menu {
  position: relative;
  display: flex;
  gap: 1.5rem;
  z-index: var(--layer-sun);
  align-items: center;

  button {
    background: none;
    border: none;
    padding: 0;
  }

  @include not-supports-gap {
    > button + button {
      margin-left: 1.5rem;
    }
  }

  .user {
    cursor: pointer;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .user span {
    padding-top: 0.3rem;
    font-size: 0.8rem;
  }
  .hamburger {
    cursor: pointer;
  }
}

.howItWorks {
  color: var(--color-white);
  background-color: var(--color-dark-blue-700);
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  font-weight: var(--font-weight-semibold);
  @include media(md) {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
  }
}
