@import 'src/styles/font-stack';

$border-width: 0.125rem;

// @todo Find a way to derive from the color tokens - this is a bit of a hack
$ghost-background-color-hover: rgba(0, 0, 51, 0.05);
$ghost-background-color-active: rgba(0, 0, 51, 0.1);
$ghost-inverted-background-color-hover: rgba(245, 245, 239, 0.1);
$ghost-inverted-background-color-active: rgba(245, 245, 239, 0.15);
$text-background-hover: rgba(235, 235, 233, 0.5);
$text-background-active: rgba(232, 232, 231, 0.5);

@mixin button-color($color) {
  // Note: Icon color inside buttons is managed by the button component
  @if $color {
    color: var(#{$color});
    svg {
      path {
        stroke: var(#{$color});
      }
    }
  }
}

.a {
  text-align: center;
  display: inline-block;
}

.button {
  cursor: pointer;
  border-width: $border-width;
  border-style: solid;
  @include font-button-20;

  &[disabled] {
    cursor: initial;
    @include button-color(--color-dark-blue-100);
  }

  &.hasIcon {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;

    padding-left: calc(1.25rem - $border-width);
    padding-right: calc(1.25rem - $border-width);
  }

  &.justify-center {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .trailing-icon {
      margin-left: 0;
    }
  }

  &.font-regular {
    font-weight: var(--font-weight-regular);
    font-variation-settings: 'wght' var(--font-weight-regular);
  }
}

.trailing-icon {
  margin-left: auto;
}

.stretch {
  width: 100%;
}

.primary {
  background-color: var(--color-dark-blue);
  border-color: var(--color-dark-blue);

  @include button-color(--color-stone-grey-100);

  &:hover {
    @include button-color(--color-mint-sweet);
  }

  &:active {
    border-color: var(--color-mint-sweet);
    @include button-color(--color-mint-sweet);
  }

  &[disabled] {
    background-color: var(--color-dark-blue-100);
    border-color: var(--color-dark-blue-100);
    @include button-color(--color-stone-grey-900);

    &:hover {
      @include button-color(--color-stone-grey-900);
    }
  }
}

.primary-sky-blue {
  background-color: var(--color-sky-blue);
  border-color: var(--color-sky-blue);

  @include button-color(--color-dark-blue);

  &:hover {
    background-color: var(--color-sky-blue-300);
    border-color: var(--color-sky-blue-300);
  }
}

.primary-mint-sweet {
  background-color: var(--color-mint-sweet);
  border-color: var(--color-mint-sweet);

  @include button-color(--color-dark-blue);

  &:hover {
    background-color: var(--color-mint-sweet-300);
    border-color: var(--color-mint-sweet-300);
  }
}

.primary-yellowish {
  background-color: var(--color-yellowish);
  border-color: var(--color-yellowish);

  @include button-color(--color-dark-blue);

  &:hover {
    background-color: var(--color-yellowish-300);
    border-color: var(--color-yellowish-300);
  }
}

.primary-redish {
  background-color: var(--color-redish);
  border-color: var(--color-redish);

  @include button-color(--color-dark-blue);

  &:hover {
    background-color: var(--color-redish-300);
    border-color: var(--color-redish-300);
  }
}

.ghost {
  background-color: transparent;
  border-color: var(--color-dark-blue);
  color: var(--color-text-primary);

  &:hover {
    background: $ghost-background-color-hover;
  }

  &:active {
    background: $ghost-background-color-active;
  }

  &[disabled] {
    border-color: var(--color-dark-blue-100);
    background-color: transparent;
  }
}

.ghost-inverted {
  background-color: transparent;
  border-color: var(--color-stone-grey);

  @include button-color(--color-stone-grey);

  &:hover {
    border-color: var(--color-stone-grey-900);
    background: $ghost-inverted-background-color-hover;
  }

  &:active {
    background: $ghost-inverted-background-color-active;
  }

  &[disabled] {
    border-color: var(--color-dark-blue-100);
    background-color: transparent;
  }
}

.tertiary {
  border-color: var(--color-stone-grey-300);
  background-color: var(--color-stone-grey-300);
  color: var(--color-dark-blue);

  &:hover {
    border-color: var(--color-stone-grey-700);
    background: var(--color-stone-grey-700);
  }

  &:active {
    border-color: var(--color-stone-grey-900);
    background: var(--color-stone-grey-900);
  }

  &[disabled] {
    border-color: var(--color-stone-grey-900);
    background: var(--color-stone-grey-900);
  }
}

.text {
  border-color: transparent;
  background-color: transparent;
  color: var(--color-dark-blue);

  &:hover {
    background: $text-background-hover;
  }

  &:active {
    background: $text-background-active;
  }

  &[disabled] {
    background-color: transparent;
  }
}

.link {
  border-color: transparent;
  background-color: transparent;
  text-align: left;
  color: var(--color-stone-grey-100);

  &:hover {
    background: $text-background-hover;
  }

  &:active {
    background: $text-background-active;
  }

  &[disabled] {
    background-color: transparent;
  }
}

.small {
  @include font-button-16;
  padding: calc(0.875rem - $border-width) 1.25rem;
  font-weight: var(--font-weight-medium);
  font-variation-settings: 'wght' var(--font-weight-medium);
  border-radius: 0.5rem;
}

.medium {
  @include font-button-14;
  padding: calc(1.125rem - $border-width) 1.75rem;
  border-radius: 0.75rem;

  &.hasIcon {
    padding-top: calc(1rem - $border-width);
    padding-bottom: calc(1rem - $border-width);
  }
}

.large {
  @include font-button-20;
  padding: calc(1.25rem - $border-width) 2rem;
  border-radius: 1rem;

  &.hasIcon {
    gap: 0.875rem;
  }
}
