@import 'src/styles/media';
@import 'src/styles/font-stack';

.cookiesAndPolicy {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.orgNumberAndCopyright {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media(md) {
    flex-direction: row-reverse;
  }

  span:last-child::after {
    @include media(md) {
      content: ' ';
      white-space: pre;
    }
  }
}
