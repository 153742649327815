@import 'src/styles/media';
@import 'src/styles/font-stack';

.navigation {
  display: none;
  position: relative;
  z-index: var(--layer-sun);

  &.inverted {
    .top-element {
      &,
      button {
        color: var(--inverted-header-color);
      }
    }
  }

  a,
  button {
    display: flex;
    gap: 0.5rem;
    align-items: baseline;
    @include font-title-18;
    border: none;
    background-color: transparent;
    cursor: pointer;

    .icon {
      opacity: 0.5;
    }
  }

  @include media(md) {
    display: flex;
    gap: 4.5rem;
    align-items: center;
    justify-content: space-between;
  }

  .dropdown-layout {
    position: absolute;
    z-index: 50;
    top: calc(100% + 0.5rem);
    min-width: 23.4375rem;
    &.buy {
      left: -22%;
    }
    &.company {
      left: 66%;
    }
  }
}
