@import 'src/styles/media';
@import 'src/styles/font-stack';
@import 'src/styles/not-supports-gap';

.footer {
  background-color: var(--color-redish);
  padding: 4rem 1rem 1rem 1rem;
  color: var(--color-text-primary);
  margin-top: var(--vertical-gutter-medium);

  @include media(md) {
    padding: 4rem 4rem 1.5rem 4rem;
  }

  @include media(xl) {
    padding: 8rem 8rem 2rem 8rem;
  }

  .inner {
    max-width: var(--max-size-lg);
    margin: 0 auto;
  }

  .contactSection {
    @include media(md) {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
    }
  }

  .navSection {
    padding: 1rem 1rem 0 1rem;
    margin-bottom: 2.5rem;

    @include media(md) {
      margin-bottom: 4rem;
    }

    nav {
      display: flex;
      flex-direction: column;
      gap: 1.5625rem;

      @include media(md) {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  .socialSection {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 2rem;

    @include media(md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      gap: 0.625rem;
      margin-bottom: 4rem;
    }

    @include media(xl) {
      margin-bottom: 5rem;
    }
  }

  .blippInfoSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.5rem;
    margin-bottom: 2.5rem;

    @include not-supports-gap {
      > svg + p {
        margin-top: 1.5rem;
      }
    }

    @include media(md) {
      display: none;
    }

    &.tablet {
      display: none;

      @include media(md) {
        padding: 0 1rem;
        display: flex;
        justify-content: flex-start;
        gap: 2.5rem;
        margin-bottom: 4rem;
      }
    }
  }

  .blippAdminInfoSection {
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;

    @include font-regular-14;
    color: var(--color-white);

    @include media(md) {
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }

  hr {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

    @include media(md) {
      margin-top: 4rem;
      margin-bottom: 4rem;

      &:last-of-type {
        display: none;
      }
    }

    @include media(xl) {
      margin-top: 5rem;
      margin-bottom: 5rem;
    }
  }

  @include media(xl) {
    .sectionWrapper {
      padding: 0 1rem;
      display: flex;
      justify-content: space-between;
      gap: 4rem;
      margin-bottom: 5rem;

      .blippInfoSection {
        padding: 0;
        margin: 0;
        width: 25%;
        height: fit-content;
      }

      .navSection {
        padding: 0;
        margin: 0;
        width: 75%;
        gap: 4rem;
      }
    }
  }
}
