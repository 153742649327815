@import 'src/styles/media';
@import 'src/styles/font-stack';

$easing: cubic-bezier(0.96, 0, 0.29, 1.01);

.container {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: var(--color-sky-blue);
  background-image: url('/assets/logotype/symbol-rotated2.svg');
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  transform: translateX(100%);
  transition: transform 350ms $easing;
  z-index: 100;

  &.open {
    transform: translateX(0);
  }

  @include media(md) {
    transition: transform 450ms $easing;
    background-image: url('/assets/logotype/symbol-rotated.svg');
    background-size: cover;
    background-position: bottom right;
  }
}

.wrapper {
  max-width: var(--max-size-lg);
  position: relative;
  padding: 4rem 1.5rem 0;
  width: 100%;
  min-height: 100%;

  @include media(md) {
    min-height: auto;
    padding-bottom: 4rem;
  }
}

.close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}

.logotype {
  width: 6.25rem;
  height: 2.9296875rem;

  @include media(md) {
    width: 10.625rem;
    height: auto;
    margin-bottom: 1rem;
  }

  path {
    fill: var(--color-white);
  }
}

.tagline {
  display: none;

  @include media(md) {
    display: block;
    @include font-display-70;
  }
}

.main-nav,
.secondary-nav,
.main-nav-links-mobile {
  display: block;
}

.main-nav {
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 1.5rem;
  min-height: calc(100% - 3.375rem);

  @include media(md) {
    margin-bottom: 7.25rem;
    padding-top: 0;
    padding-bottom: 0;
    min-height: auto;
  }

  .main-nav-links {
    display: none;

    @include media(md) {
      display: flex;
      gap: 1.5rem;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    a {
      @include media(md) {
        min-width: 12rem;
      }
    }
  }

  .main-nav-links-mobile {
    display: flex;
    flex-direction: column;
    flex: 1;

    @include media(md) {
      display: none;
    }

    .accordion-buttons {
      margin: 0 -1.125rem;
    }
  }

  .main-nav-links-profile {
    margin-bottom: 3rem;
  }

  .login-button {
    margin-top: auto;
    padding-top: 1.5rem;

    @include media(md) {
      margin-top: 0;
      padding-top: 0;
    }

    @include media(lg) {
      margin-left: auto;
    }
  }
}

.secondary-nav {
  display: none;

  @include media(md) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 2rem;
  }

  .secondary-nav-col {
    min-width: 14rem;

    h3 {
      @include font-title-32;
    }

    ul {
      margin: 0 -1.125rem;
      padding: 0;
      list-style: none;
    }
  }
}
