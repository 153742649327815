@import 'src/styles/media';
@import 'src/styles/font-stack';
@import 'src/styles/not-supports-gap';

.trustpilot {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .trustpilotLogotype {
    width: auto;
    height: 1.25rem;
  }

  .rating {
    @include font-display-50;
    color: var(--color-text-inverted);
    margin-bottom: 0;

    .bigger {
      @include media(md) {
        @include font-display-70;
      }
    }
  }
}

.socialMediaIcons {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1rem;

  @include not-supports-gap {
    > a + a {
      margin-left: 1.5rem;
    }
  }
}
