// @todo refactor `shadow` into a reusable mixin
// - it should be able to take theme color as shadow color
// - how to handle alpha channel?
//  - with sass:color? color.change()
@mixin shadow {
  box-shadow: 0px 7px 16px -2px #3a3d551f, 0px 3px 20px -2px #3a3d551a, 0px 5px 32px -4px #3a3d550d;
}

.dropdown {
  $dropdown-background: var(--color-stone-grey);
  $arrow-edge-width: 0.375rem;
  $arrow-height: 0.4375rem;
  $arrow-box-width: $arrow-edge-width * 2;

  position: relative;
  transform: translateY($arrow-height);
  border-radius: 1rem;
  color: var(--color-dark-blue);

  .arrow {
    position: relative;
    margin: 0 auto;
    width: $arrow-box-width;
    height: $arrow-height;

    &:before {
      content: '';
      position: absolute;
      top: $arrow-height * -1;
      border-left: $arrow-edge-width solid transparent;
      border-right: $arrow-edge-width solid transparent;
      border-bottom: $arrow-height solid $dropdown-background;
    }
  }

  @include shadow;

  .content {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    background-color: $dropdown-background;
    padding: 1rem 0.5rem;
    margin-top: $arrow-height * -1;
  }
}
